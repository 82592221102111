<app-toolbar></app-toolbar>
    <!-- CAROUSEL -->

    <div id="main-carousel" class="carousel slide mb-5" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              id="carouselImg"
              class="d-inline w-100"
              src="https://source.unsplash.com/1600x450/?computers"
              alt="First slide"
            />
            <div class="carousel-caption d-none d-md-block">
              <h3>
                IR35 Compliant contingency: Projects delivered as a service
              </h3>
              <h4>
                Getting your projects back on track with IR35 compliant resources
              </h4>
            </div>
          </div>
          <div class="carousel-item">
            <img
              id="carouselImg"
              class="d-inline w-100"
              src="https://source.unsplash.com/1601x450/?computers"
              alt="Second slide"
            />
            <div class="carousel-caption d-none d-md-block">
              <h3>
                Detailed and transparent work packages and billing
              </h3>
              <h4>
                Know exactly what you're paying for with our detailed plans and
                time tracking
              </h4>
            </div>
          </div>
          <div class="carousel-item">
            <img
              id="carouselImg"
              class="d-inline w-100"
              src="https://source.unsplash.com/1602x450/?computers"
              alt="Third slide"
            />
            <div class="carousel-caption d-none d-md-block">
              <h3>
                Application support and administration as a service
              </h3>
              <h4>
                Support and administration of critical applications so you can
                focus on what you do best
              </h4>
            </div>
          </div>
        </div>
        <a
          class="carousel-control-prev"
          href="#main-carousel"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#main-carousel"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
  
      <!-- PARAGRAPH -->
  
      <div class="text-left my-5 mx-5">
        <h2 class="my-3">IT as a service: IR35 Compliant</h2>
        <h4 class="my-3">What is ITAAS?</h4>
        <p class="text-left">
          ITAAS(IT as a service) gives you complete control of how you flex your
          organisation to meet its IT demands. Whether it's project management,
          business analysis, software development, or support; we can deliver
          discreet packages of work with no ongoing commitment or overhead.
        </p>
        <h4 class="my-3">How does it work?</h4>
        <p class="text-left">
          We discuss your requirements and agree with you a schedule of work and a
          defined set of outputs. Once this has been agreed we'll allocate the
          work items to our team for completion. You'll receive regular progress
          reports from us during the course of the deliverable. Once the
          deliverable is completed you'll receive detailed documentation and
          handover.
        </p>
      </div>
  
      <hr />
  
      <div class="text-left my-5 mx-5">
        <h2 class="my-3">How can we help?</h2>
        <h4 class="my-3">Managing your projects</h4>
        <p class="text-left">
          We can assist with any and all phases of the project lifecycle including
          intervention in failing projects. Our approach will ensure that project
          tasks are planned and tracked at the appropriate level, good governance
          is in place, and that budgets are under control. Regular reporting and
          review will ensure risks and issues are surfaced early and that you're
          empowered to make the important decisions affecting your project
          delivery.
        </p>
        <h4 class="my-3">Analysing your business and data</h4>
        <p class="text-left">
          Let our experts in business and data analysis assist with requirements
          gathering, process mapping, re-design and data analysis. Our in-depth
          documentation will ensure that you have the information that you need to
          deliver successful projects and change.
        </p>
        <h4 class="my-3">Supporting and administering your existing software</h4>
        <p class="text-left">
          We're experts at learning new software packages. Release capacity from
          your support and business teams by on-boarding the support and
          administration of third party software with us. We can work with you to
          make sure that your business process and escalation workflows are
          seamlessly followed and act as your representative with the vendor if
          required. Alternatively we can produce user friendly documentation and
          training to enable your staff to efficiently support your business.
        </p>
        <h4 class="my-3">Maintaining your codebase and creating new apps</h4>
        <p class="text-left">
          Perhaps you have bugs in legacy software, a key resource has left the
          company, or there are no resources available? Whether it's fixing bugs,
          documenting legacy software, or creating new software, we can help make
          your software work for your business. All development remains your
          intellectual property and will be comprehensively documented.
        </p>
      </div>
  <app-footer></app-footer>
