<!-- HEADER/NAV -->
<section id="header">
<nav class="navbar navbar-expand-sm navbar-light bg-light">
    <div class="container">
    <a class="navbar-brand my-auto" href="#"><img src="/assets/MainLogo.png"></a>
    <a alt="Contact Us" href="mailto:info@feralrabbit.co.uk" class="btn btn-dark mt-3 mb-3">Contact Us</a>
<!--    <ul class="navbar-nav">
        <li class="nav-item">
        <div
            class="modal fade"
            id="modalContactForm"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header text-center">
                <h4 class="modal-title w-100 font-weight-bold">
                    Tell us what you think
                </h4>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body mx-3">
                <div class="md-form mb-2">
                    <input
                    type="text"
                    id="form-name"
                    class="form-control validate"
                    placeholder="Your Name"
                    />
                </div>

                <div class="md-form mb-2">
                    <input
                    type="email"
                    id="form-email"
                    class="form-control validate"
                    placeholder="Email"
                    />
                </div>

                <div class="md-form mb-2">
                    <textarea
                    class="form-control"
                    style="min-width: 100%;"
                    name="Message"
                    id="form-message"
                    placeholder="Your Message"
                    ></textarea>
                </div>
                </div>
                <div class="modal-footer d-flex justify-content-center">
                <button class="btn btn-outline-primary">Send</button>
                </div>
            </div>
            </div>
        </div>

        <div>
            <a
            id="modalAnchor"
            onclick="this.blur();"
            href="#"
            class="btn btn-default btn-rounded mt-4 text-white bg-dark"
            data-toggle="modal"
            data-target="#modalContactForm"
            >Contact Us</a
            >
        </div>
        </li>
    </ul>
-->
</div>
</nav>
</section>
