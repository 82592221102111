<!-- Footer -->
<footer class="page-footer font-small">

        <!-- Footer Links -->
        <div class="container-fluid pl-1 pr-1">
            <nav class="navbar navbar-dark bg-dark rounded">
            </nav>
        </div>
        <!-- Footer Links -->
      
        <!-- Copyright -->
        <div class="footer-copyright text-center py-3">© 2019-2020 Copyright: Feral Rabbit Ltd
        </div>
        <!-- Copyright -->
      
      </footer>
      <!-- Footer -->